import React, { useState, useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { investorContext, authContext } from "../context/context";
import countryList from "react-select-country-list";
import {
  ImageGridItem,
  StateSelector,
  Modal,
  Hover,
  InvestorContact
} from "@peterfosso/prairi-components";
import NextButton from "./../common/NextButton/NextButton";
import CompanyLogo from "../../assets/company2.png";
import Switch from "react-switch";
import CatInvestor from "../../assets/investorcat.jpeg";
import { withStyles } from "@material-ui/core/styles";
import NoPoverty from "../../assets/SDG/E-WEB-Goal-01.png";
import ZeroHunger from "../../assets/SDG/E-WEB-Goal-02.png";
import GoodHealth from "../../assets/SDG/E-WEB-Goal-03.png";
import QualityEducation from "../../assets/SDG/E-WEB-Goal-04.png";
import GenderEquality from "../../assets/SDG/E-WEB-Goal-05.png";
import CleanWater from "../../assets/SDG/E-WEB-Goal-06.png";
import CleanEnergy from "../../assets/SDG/E-WEB-Goal-07.png";
import DecentWork from "../../assets/SDG/E-WEB-Goal-08.png";
import Innovation from "../../assets/SDG/E-WEB-Goal-09.png";
import ReducedInequalities from "../../assets/SDG/E-WEB-Goal-10.png";
import SustainableCities from "../../assets/SDG/E-WEB-Goal-11.png";
import ResponsibleConsumption from "../../assets/SDG/E-WEB-Goal-12.png";
import ClimateAction from "../../assets/SDG/E-WEB-Goal-13.png";
import LifeBelowWater from "../../assets/SDG/E-WEB-Goal-14.png";
import LifeOnLand from "../../assets/SDG/E-WEB-Goal-15.png";
import Peace from "../../assets/SDG/E-WEB-Goal-16.png";
import Partnerships from "../../assets/SDG/E-WEB-Goal-17.png";
import SDG from "../../assets/SDG/SDGs-transparent.png";
import { storage } from "../../firebase/firebaseApp";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import ProcessingLogo from "../../assets/processing.png";
import { CountryDropdown } from "react-country-region-selector";
import { countryRegions, usStates } from "../../countries.js";
import { useMediaQuery } from "react-responsive";
import {
  query,
  collection,
  db,
  where,
  getDocs,
  Timestamp,
  getDoc,
  doc,
  updateDoc,
} from "../../firebase/firebaseApp";
import { TextButton } from "@peterfosso/prairi-components";
import { updateLatestActions } from "../../utils";

const InvestorCompany = (props) => {
  const { setDataFunc, globalData } = useContext(investorContext);
  const { currentUser } = useContext(authContext);
  const companyData = globalData.formData.company;
  const [docId, setDocId] = useState(null);
  const [locationModal, setLocationModal] = useState(false);
  const [please, setPlease] = useState(false);
  const [sorry, setSorry] = useState(false);
  const [showPreview, setShowPreview] = useState(false)
  const [userRef, setUserRef] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null)
  const [statesError, setStatesError] = useState("");
  const [showStateSelector, setShowStateSelector] = useState(false);
  const [data, setData] = useState({
    company: companyData.company || globalData.companyName || "",
    contactName: companyData.contactName || globalData.username || "",
    country: companyData.country || globalData.country || "",
    // state: "",
    selectedStartupCountry: "Select countries / regions",
    startupcountries: companyData.startupcountries || "",
    startupstates: companyData?.startupstates || usStates,
    investments: companyData.investments || globalData.previous || "",
    investmentGroups: companyData.investmentGroups || globalData.groups || "",
    majority: companyData.majority,
    startupAllocates:
      companyData.startupAllocates || globalData.startupPays ? true : false,
    goals: companyData.goals || "",
    goalsBool: companyData.goalsBool || "",
    photo: companyData.photo || "",
    error: "",
    loading: false,
    message: "",
  });
  const [showGoals, setShowGoals] = useState(false);
  const [photoFile, setPhotoFile] = useState(null);
  const [shownPhoto, setShownPhoto] = useState(companyData.photo || "")
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [preSelectedStates, setPreSelectedStates] = useState(
    companyData.startupstates
  );

  const handleGoalsBoolean = (event) => {
    setData({
      ...data,
      goalsBool: event.target.value,
    });
  };

  const handleSelectStates = (state) => {
    const { startupstates } = data;

    const isSelected = preSelectedStates.includes(state);

    const updatedSelectedStates = isSelected
      ? preSelectedStates.filter((item) => item !== state)
      : [...preSelectedStates, state];

    setPreSelectedStates(updatedSelectedStates);
    
  };

  const selectAllStates = () => {
    setPreSelectedStates(usStates);
  };

  const clearAllStates = () => {
    setPreSelectedStates([]);
  };

  const handleSaveStates = async () => {
    await updateDoc(userRef, { "states": preSelectedStates });
    setData((prevData) => ({
      ...prevData,
      startupstates: preSelectedStates,
    }));
    setShowStateSelector(false);
  };


  const handleUpload = async (e) => {
    const selectedFile = e.target.files[0];
    
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
  
    // Crea un objeto de imagen
    const img = new Image();
    const reader = new FileReader();
  
    reader.onload = (event) => {
      img.src = event.target.result;
  
      img.onload = () => {
        // Crea un canvas para redimensionar la imagen
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        // Establece el tamaño del canvas
        canvas.width = 200;
        canvas.height = 200;
  
        // Dibuja la imagen en el canvas con el nuevo tamaño
        ctx.drawImage(img, 0, 0, 200, 200);
  
        // Convierte el contenido del canvas a un blob
        canvas.toBlob(async (blob) => { // Aquí se hace async porque se va a usar await dentro
          const resizedFile = new File([blob], selectedFile.name, { type: selectedFile.type });
  
          // Actualiza el estado para mostrar la vista previa de la imagen redimensionada
          setShownPhoto(URL.createObjectURL(resizedFile));
  
          // Sube la imagen redimensionada
          const storageRef = ref(storage, `investorPhotos/${resizedFile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, resizedFile);
  
          uploadTask.on(
            "state_changed",
            null,
            (error) => {
              console.error("Upload failed:", error);
            },
            async () => { // También se necesita async aquí
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              await updateDoc(userRef, { "photo": downloadURL });
              setData((prevData) => ({
                ...prevData,
                photo: downloadURL,
              }));
            }

          );
          
        }, selectedFile.type);
      };
    };
  
    reader.readAsDataURL(selectedFile);
  };
  
  
  const handleAllocatesBoolean = (event) => {
    setData({
      ...data,
      startupAllocates: event.target.value === "true", // Asegúrate de que sea un booleano
    });
  };

  const goalsHandler = (value) => {
    setData({
      ...data,
      goals: goals.includes(value)
        ? goals.filter((goal) => goal !== value)
        : [...goals, value],
    });
  };

  const WhiteRadio = withStyles({
    root: {
      color: "white",
      "&$checked": {
        color: "green",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const getDataFromDb = async () => {
    try {
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;

      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
        setUserRef(doc.ref);
        setCurrentUserData(doc.data())
        if (!doc.data().formData) {
          companyData.contactName = companyData.contactName || doc.data().contactName || "";
          companyData.country = companyData.country || doc.data().country || "";
          companyData.company = companyData.company || doc.data().companyName || "";
          companyData.investments =
            companyData.investments || doc.data().previous || "";
          companyData.investmentGroups =
            companyData.investmentGroups || doc.data().groups || "";
          companyData.startupAllocates =
            companyData.startupAllocates || doc.data().startupPays === true;

          // Update data state
          setData((prevState) => ({
            ...prevState,
            country: doc.data().country,
            company: doc.data().companyName,
            investments: doc.data().previous,
            investmentGroups: doc.data().groups,
            startupAllocates: doc.data().startupPays === true,
            startupstates: usStates, // Set default states
          }));
        } else {
          const companyData = doc.data().formData.company;

          setData((prevState) => ({
            ...prevState,
            country: companyData.country,
            company: companyData.company,
            investments: companyData.investments,
            investmentGroups: companyData.investmentGroups,
            startupAllocates: companyData.startupAllocates,
            startupstates: companyData.startupstates || usStates,
          }));
        }
      });

      return { userData, userId };
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  //
  useEffect(() => {
    const getUpdatedData = () => {
      setData((prevData) => ({ ...prevData, ...companyData }));
    };
    getUpdatedData();
  }, [companyData, globalData]);

  const storeFormView = async (e) => {
    try {
      const { userData, userId } = await getDataFromDb();
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userEmail = userSnap.data().email;
        const preUsersRef = collection(db, "preUsers");
        const q = query(preUsersRef, where("email", "==", userEmail));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const preUserDoc = querySnapshot.docs[0];
          const preUserRef = doc(db, "preUsers", preUserDoc.id);
          if (!preUserDoc.data().formFirstView) {
            await updateDoc(preUserRef, { formFirstView: true });
            //updateLatestActions(preUserRef, "Forms first view", isMobile);
          } else {
            return;
          }
        } else {
          console.log("No matching preUser document found.");
        }
      } else {
        console.log("User document does not exist.");
      }
    } catch (error) {
      console.log("Error updating document:", error);
    }
  };



  useEffect(() => {
    if (currentUser) {
      const fetchData = async () => {
        const { userData, userId } = await getDataFromDb();
        setDocId(userId);
      };
      fetchData();
    }
  }, [currentUser, companyData, shownPhoto]);

  let navigate = useNavigate();

  const options = useMemo(() => countryList().getLabels(), []);
  const countriesRegion = [
    "Select countries / regions",
    "ANYWHERE",
    "AFRICA",
    "ASIA",
    "CENTRAL AMERICA",
    "EUROPE",
    "NORDICS",
    "MIDDLE EAST",
    "NORTH AMERICA",
    "SOUTH AMERICA",
    "OCEANIA",
    ...options,
  ];

  const majoritySelectOptions = [
    {
      name: "Equity investments (cash for ownership)",
      value: "Equity investments (cash for ownership)",
    },
    {
      name: "Revenue-based financing (lending based on startup's revenue)",
      value: "Revenue-based financing (lending based on startup's revenue)",
    },
    {
      name: "Services in exchange for equity (little or no cash provided)",
      value: "Services in exchange for equity (little or no cash provided)",
    },
  ];

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setData({
      company: companyData.company,
      country: companyData.country,
      contactName: companyData.contactName,
      state: companyData.state || "",
      // selectedStartupCountry: "Select countries / regions",
      startupcountries: companyData.startupcountries,
      startupstates: companyData.startupstates,
      investments: companyData.investments,
      investmentGroups: companyData.investmentGroups,
      startupAllocates: companyData.startupAllocates,
      majority: companyData.majority,
      photo: companyData.photo,
      goals: companyData.goals || [],
      goalsBool: companyData.goalsBool || "",
      error: "",
      loading: false,
      message: "",
    });
  }, [companyData]);

  const handleStartupCountryChange = (selectedCountry) => {
    if (selectedCountry) {
      const selectedCountriesArray = startupcountries;
      let filteredArray = selectedCountriesArray.filter(
        (countryName) => countryName !== selectedCountry
      );
      if (countriesRegion.slice(2, 11).includes(selectedCountry)) {
        filteredArray = [
          ...filteredArray,
          ...countryRegions[selectedCountry],
          selectedCountry,
        ];
        let set = new Set(filteredArray);
        filteredArray = Array.from(set);
      } else {
        filteredArray.push(selectedCountry);
        let set = new Set(filteredArray);
        filteredArray = Array.from(set);
      }
      setData({
        ...data,
        startupcountries: [...filteredArray],
        selectedStartupCountry: selectedCountry,
      });
    }
  };

  const removeCountryHandler = (countryName) => {
    let filteredArray = [...startupcountries];
    let regionsForCountry = [];

    if (Object.keys(countryRegions).includes(countryName)) {
      filteredArray = filteredArray.filter(
        (country) => !countryRegions[countryName].includes(country)
      );
      // console.log("Delete region and its countries");
      filteredArray = filteredArray.filter(
        (country) => country !== countryName
      );
    } else {
      for (const region in countryRegions) {
        if (countryRegions[region].includes(countryName)) {
          regionsForCountry.push(region);
          // console.log("Region from country: " + region);
        }
      }

      for (const regionToRemove of regionsForCountry) {
        let counter = 0;
        for (const otherCountry of countryRegions[regionToRemove]) {
          if (filteredArray.includes(otherCountry)) {
            counter++;
            // console.log(counter);
          }
        }
        if (counter === 1) {
          // Remove the region if no other country from that region exists
          // console.log(counter);
          filteredArray = filteredArray.filter(
            (country) => country !== regionToRemove
          );
        }
      }

      filteredArray = filteredArray.filter(
        (country) => country !== countryName
      );
    }

    setData({
      ...data,
      startupcountries: filteredArray,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    storeFormView();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const companyName = company.trim();
    const companyCountry = country.trim() || globalData.country;
    let majorities = [];
    majorities = Object.keys(majority).filter((key) => majority[key]);
    if (
      !companyCountry ||
      startupcountries.length < 1 ||
      majorities.length < 1
    ) {
      setData({ ...data, error: "Fill all the required fields." });
    } else {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Saving... (click progress bar if you wish to edit)",
      });
      // Deselecting

      // Deselecting all industries in next section if 'ANYWHERE' is selected in match processing
      const moreThan70Selected =
        startupcountries.includes("ANYWHERE") || startupcountries.length > 69;
      const { industry } = globalData.formData.industry;
      const newObj = {};
      const time = Timestamp.fromDate(new Date());
      const latestTime = time
        .toDate()
        .toLocaleDateString("en-US", { month: "2-digit", day: "2-digit" });
      const checkedValues = Object.values(industry).filter(Boolean).length;
      Object.keys(industry).forEach((key) => (newObj[key] = false));
      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            company: {
              ...globalData.formData.company,
              company: companyName,
              contactName,
              country,
              state,
              startupcountries,
              startupstates,
              photo,
              investments,
              investmentGroups,
              startupAllocates,
              majority,
              goals,
              goalsBool,
              completed: true,
            },
            industry: {
              ...globalData.formData.industry,
              industry:
                moreThan70Selected && checkedValues > 7 ? newObj : industry,
            },
          },
        });
        navigate("/investorregistration/industry");
        let ref = doc(db, "users", docId);
        updateLatestActions(ref, "Saved company form", isMobile);
      }, 2000);
    }
  };
  const {
    company,
    country,
    state,
    contactName,
    selectedStartupCountry,
    startupcountries,
    photo,
    startupstates,
    investments,
    investmentGroups,
    startupAllocates,
    majority,
    goals,
    goalsBool,
    message,
    error,
    //loading,
  } = data;

  const hoverContent = <div className=" lg:w-[200px] md:w-[180px] w-[130px] lg:text-sm ">Seen only when you click <span className="text-greenPrairi">'Interested' </span>on a startup match. {currentUserData?.formData?.company?.completed && <span className="text-silverPrairi italic cursor-pointer" onClick={()=>setShowPreview(true)}>(See preview)</span>} </div>
  return (
    <div className="shadow-grey w-[90%]   rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <form className=" " onSubmit={handleSubmit}>
        <div className="my-2 ">
          <div className="flex items-end pl-3 md:pl-0">
            <div
              className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
            >
              <img className=" h-full  " src={CompanyLogo} alt="Company" />
            </div>
            <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
              Company
            </h1>
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="company">Your Name</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="contactName"
              id="contactName"
              placeholder="Enter your name"
              value={contactName || globalData.username}
              onChange={handleChange}
            ></input>
          </div>
          <div className="mt-5">
            <div className="mt-5 px-3 md:pl-24 flex items-center">
              <p>Your photo (recommended)    </p>           <Hover hoverContent={hoverContent}>
              <div
                className="text-silverPrairi bg-[black] rounded-full w-[20px] h-[20px] flex items-center justify-center mr-2 cursor-pointer ml-2"
                // onClick={() => setQuestionPopup(!questionPopup)}
              >
                ?
              </div>
                </Hover>
                {showPreview && <div className="z-50 absolute  w-[350px] left-50 bottom-5"><InvestorContact investor={currentUserData} example dashboard close={()=>setShowPreview(false)}/></div>}
            </div>

            <div className="flex items-center justify-start mt-5 px-3 md:pl-24">
              {/* <img src={CatInvestor} className="w-[100px] h-[100px] rounded-full"></img> */}
              {!photo && <div className="w-[130px] h-[100px] bg-silverPrairi rounded-full"></div>}
              {photo && <img src={shownPhoto || photo} className="w-[100px] h-[100px] rounded-full"></img>}
  {!photo && <div className="flex items-center justify-start w-full">
              <input
                type="file"
                name="photo"
                id="photo"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleUpload}
                className="hidden"
              />
              <label
                htmlFor="photo"
                className="ml-4 py-2 my-2 bg-[#3A3A3A] text-sm w-1/2 text-white border-yellowPrairi text-center border rounded cursor-pointer hover:bg-[#262626]"
              >
                Upload 
              </label>
              <p className="ml-4 italic text-redPrairi italic  runcate w-[70%]">
                Photo missing
              </p>
              </div>}
              {photo && <div className="flex items-center justify-start w-full">
              <input
                type="file"
                name="photo"
                id="photo"
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleUpload}
                className="hidden"
              />
              <label
                htmlFor="photo"
                className="ml-4 py-2 my-2 bg-[#3A3A3A] w-1/2 text-sm px-2 text-white border-greenPrairi text-center border rounded cursor-pointer hover:bg-[#262626]"
              >
                Upload 
              </label>
              <p className="ml-4 italic text-greenPrairi italic  runcate w-[70%]">
                This is your current photo
              </p>
              </div>}
            </div>
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="company">Company Name</label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="company"
              id="company"
              placeholder="Enter Company's name"
              value={company || globalData.companyName}
              onChange={handleChange}
            ></input>
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="country">
              Location of Investor / Company (country where you are officially
              headquartered)
            </label>
            <CountryDropdown
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              value={country || globalData.country}
              onChange={(country) => setData({ ...data, country })}
              name="country"
              id="country"
            />
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="investments">
              Previous startup investments (by you or your firm)
            </label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="investments"
              id="investments"
              placeholder="Names of various startups"
              value={investments || globalData.previous}
              onChange={handleChange}
            ></input>
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="investmentGroups">
              If you're a member of any investment groups, please mention them
              here
            </label>
            <input
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              type="text"
              name="investmentGroups"
              id="investmentGroups"
              value={investmentGroups || globalData.groups}
              onChange={handleChange}
            ></input>
          </div>

          <div className="my-4 w-[90%]">
            <h1 className="mt-5  pl-3 md:pl-24  text-white ">
              The majority of your investments are likely to be in:
            </h1>
            {majoritySelectOptions.map((selection) => {
              return (
                <div
                  key={selection.value}
                  className="mt-3 px-3 md:pl-24 lg:w-[130%] flex items-center "
                >
                  <Switch
                    onChange={() =>
                      setData({
                        ...data,
                        majority: {
                          ...majority,
                          [selection.value]: !majority[selection.value],
                        },
                      })
                    }
                    checked={majority[selection.value]}
                    id={selection.value}
                  />
                  <label htmlFor={selection.value} className="ml-5">
                    {selection.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center justify-center">
          {(startupAllocates !== undefined || doc.data()) && (
            <div className=" pl-3 md:pl-24 my-4">
              <p>PRAIRI's fee shall be paid by us</p>
              <div className="flex items-center justify-center">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="startupAllocates"
                    name="goals"
                    onChange={handleAllocatesBoolean}
                    value={
                      startupAllocates !== undefined
                        ? startupAllocates.toString()
                        : (globalData.startupPays === true).toString()
                    }
                    row
                  >
                    <FormControlLabel
                      color="success"
                      value="false"
                      control={<WhiteRadio />}
                      label={
                        <span className="mr-10">
                          and <span className="underline">not</span> be taken
                          from the startup's share
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="true"
                      control={<WhiteRadio />}
                      label={
                        <span className="">
                          allocated from the startup's share
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          )}
        </div>
        <div className="my-5 ">
          <div className="flex items-end pl-3 md:pl-0">
            <div
              className={` h-10 md:h-12 flex items-center justify-center mr-2 md:mr-4 `}
            >
              <img className=" h-full  " src={ProcessingLogo} alt="Company" />
            </div>
            <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
              Match Processing
            </h1>
          </div>
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="startupcountries">
              Acceptable countries/regions of startups
            </label>
            <div className="w-full my-2 ">
              <select
                className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                value={selectedStartupCountry}
                onChange={(e) => handleStartupCountryChange(e.target.value)}
                id="startupcountries"
              >
                {countriesRegion.map((countryRegion, index) => {
                  return (
                    <option
                      disabled={index === 0 ? true : false}
                      value={countryRegion}
                      key={countryRegion}
                    >
                      {countryRegion}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {startupcountries.length > 0 && (
            <div className="mt-5 px-3 md:pl-24">
              <div className="border-blackish  pb-5 w-full  flex flex-wrap rounded border  text-black bg-white">
                {startupcountries.map((countryName) => {
                  return (
                    <div>
                      <div
                        className="border rounded border-blackish text-sm p-2 m-1"
                        key={countryName}
                      >
                        <span className="mr-2">{countryName}</span>
                        <button
                          onClick={() => removeCountryHandler(countryName)}
                          className="bg-gray-700 px-1 py-0 text-white"
                        >
                          x
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-end">
                <TextButton
                  label="Clear all"
                  color="silver"
                  click={() => setData({ ...data, startupcountries: [] })}
                />
              </div>
            </div>
          )}
          {startupcountries.includes("United States") &&
            startupstates.length === usStates.length && (
              <p className="mt-5 px-3 md:pl-24 italic text-silverPrairi">
                You selected the United States. By default, you'll be matched
                with startups in <span className="font-bold">any</span> state.{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => setShowStateSelector(true)}
                >
                  Click here
                </span>{" "}
                to select specific states.
              </p>
            )}
          {startupcountries.includes("United States") &&
            startupstates.length < usStates.length && (
              <p className="mt-5 px-3 md:pl-24 italic text-silverPrairi">
                You selected the United States. Currently, you have selected the
                following states:{" "}
                <span className="pr-3 ont-bold">
                  {startupstates.join(", ")}.
                </span>
                <span
                  className="underline cursor-pointer text-[#f2f2f2]"
                  onClick={() => setShowStateSelector(true)}
                >
                  Click here
                </span>{" "}
                to modify your selection.
              </p>
            )}

          <StateSelector
            selectedStates={preSelectedStates}
            open={showStateSelector}
            onClose={() => setShowStateSelector(false)}
            handleSelect={handleSelectStates}
            selectAll={selectAllStates}
            clearAll={clearAllStates}
            handleSave={handleSaveStates}
            error={preSelectedStates.length === 0}
          />
        </div>

        <div className=" pl-3 md:pl-24 my-4">
          <p>
            Do you have a preference toward startups working on any specific
            SDGs?
          </p>
          <div className=" flex items-center justify-center ">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="goals"
                name="goals"
                onChange={handleGoalsBoolean}
                value={showGoals}
                row
              >
                <FormControlLabel
                  color="success"
                  value="Yes"
                  control={<WhiteRadio checked={goalsBool === "Yes"} />}
                  label={<span className="mr-10">Yes</span>}
                />
                <FormControlLabel
                  value="No"
                  control={<WhiteRadio checked={goalsBool === "No"} />}
                  label={<span className="">No</span>}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        {goalsBool === "Yes" && (
          <div className=" pl-3 md:pl-24 mt-4 mb-8">
            <p className="mb-2">Select one or more from the following:</p>
            <div className="grid grid-cols-4 grid-rows-5 gap-4">
              <ImageGridItem
                src={NoPoverty}
                click={() => goalsHandler("No Poverty")}
                value="No Poverty"
                selected={goals.includes("No Poverty")}
              />
              <ImageGridItem
                src={ZeroHunger}
                click={() => goalsHandler("Zero Hunger")}
                value="Zero Hunger"
                selected={goals.includes("Zero Hunger")}
              />
              <ImageGridItem
                src={GoodHealth}
                click={() => goalsHandler("Good Health and Well-being")}
                value="Good Health and Well-being"
                selected={goals.includes("Good Health and Well-being")}
              />
              <ImageGridItem
                src={QualityEducation}
                click={() => goalsHandler("Quality Education")}
                value="Quality Education"
                selected={goals.includes("Quality Education")}
              />
              <ImageGridItem
                src={GenderEquality}
                click={() => goalsHandler("Gender Equality")}
                value="Gender Equality"
                selected={goals.includes("Gender Equality")}
              />
              <ImageGridItem
                src={CleanWater}
                click={() => goalsHandler("Clean Water and Sanitation")}
                value="Clean Water and Sanitation"
                selected={goals.includes("Clean Water and Sanitation")}
              />
              <ImageGridItem
                src={CleanEnergy}
                click={() => goalsHandler("Affordable and Clean Energy")}
                value="Affordable and Clean Energy"
                selected={goals.includes("Affordable and Clean Energy")}
              />
              <ImageGridItem
                src={DecentWork}
                click={() => goalsHandler("Decent Work and Economic Growth")}
                value="Decent Work and Economic Growth"
                selected={goals.includes("Decent Work and Economic Growth")}
              />
              <ImageGridItem
                src={Innovation}
                click={() =>
                  goalsHandler("Industry, Innovation and Infrastructure")
                }
                value="Industry, Innovation and Infrastructure"
                selected={goals.includes(
                  "Industry, Innovation and Infrastructure"
                )}
              />
              <ImageGridItem
                src={ReducedInequalities}
                click={() => goalsHandler("Reduced Inequalities")}
                value="Reduced Inequalities"
                selected={goals.includes("Reduced Inequalities")}
              />
              <ImageGridItem
                src={SustainableCities}
                click={() => goalsHandler("Sustainable Cities and Communities")}
                value="Sustainable Cities and Communities"
                selected={goals.includes("Sustainable Cities and Communities")}
              />
              <ImageGridItem
                src={ResponsibleConsumption}
                click={() =>
                  goalsHandler("Responsible Consumption and Production")
                }
                value="Responsible Consumption and Production"
                selected={goals.includes(
                  "Responsible Consumption and Production"
                )}
              />
              <ImageGridItem
                src={ClimateAction}
                click={() => goalsHandler("Climate Action")}
                value="Climate Action"
                selected={goals.includes("Climate Action")}
              />
              <ImageGridItem
                src={LifeBelowWater}
                click={() => goalsHandler("Life Below Water")}
                value="Life Below Water"
                selected={goals.includes("Life Below Water")}
              />
              <ImageGridItem
                src={LifeOnLand}
                click={() => goalsHandler("Life on Land")}
                value="Life on Land"
                selected={goals.includes("Life on Land")}
              />
              <ImageGridItem
                src={Peace}
                click={() =>
                  goalsHandler("Peace, Justice and Strong Institutions")
                }
                value="Peace, Justice and Strong Institutions"
                selected={goals.includes(
                  "Peace, Justice and Strong Institutions"
                )}
              />
              <ImageGridItem
                src={Partnerships}
                click={() => goalsHandler("Partnership for The Goals")}
                value="Partnership for The Goals"
                selected={goals.includes("Partnership for The Goals")}
              />

              <img src={SDG} className="col-span-3"></img>
            </div>
          </div>
        )}
        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">
            Saving...{" "}
            <span className="text-silverPrairi">
              (click progress bar if you wish to edit)
            </span>
          </p>
        )}

        <div className="flex justify-center my-5 px-3 md:pl-24">
          <NextButton value="SAVE" />
        </div>
      </form>
    </div>
  );
};

export default InvestorCompany;