import React, { useState, useContext, useEffect } from "react";
//import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth, signOut } from "../firebase/firebaseApp";
import Logo from "../assets/prairiwhite.png";
import PrairiTextLogo from "../assets/prairitext.png";
import SilverLogo from "../assets/silver-unicorn.png";
import SilverPrairiTextLogo from "../assets/silver-prairi.png";
import { VscMenu } from "react-icons/vsc";
import { CgClose } from "react-icons/cg";
import { BiLogIn } from "react-icons/bi";
import { authContext, dataContext, investorContext, navBarContext } from "./context/context";
import { query, collection, db, where, getDocs } from "../firebase/firebaseApp";
import { mergeRefs } from "react-merge-refs";
import useMeasure from "react-use-measure";
import { useMediaQuery } from "react-responsive";
import { LogoutConfirmation, Modal } from "@peterfosso/prairi-components";

const NavBar = React.forwardRef((props, ref) => {
  const location = useLocation();
  const isLandingPage = /^\/invitation\/[a-zA-Z0-9]*$/.test(location.pathname) || 
  location.pathname === "/" || location.pathname === "/landing";

  const data = useContext(dataContext);
  const investorData = useContext(investorContext);

  // which context to use?
  const contextData = data.globalData ? data : investorData.globalData ? investorData : null;

  const isInvitationForm =
    location.pathname === "/invitation/investor" ||
    location.pathname === "/invitation/startup";
  const isDashboard = location.pathname === "/dashboard";
  const isSettings =
    location.pathname === "/settings" ||
    location.pathname === "/dataprofile/investor" ||
    location.pathname === "/dataprofile/startup" ||
    location.pathname === "/investorregistration" ||
    location.pathname === "/startupregistration" ||
    location.pathname === "/forgotpassword" ||
    location.pathname === "/matchpause" ||
    location.pathname === "/remove";

    const noNav =
    /^\/notifications\/[a-zA-Z0-9]+$/i.test(location.pathname) ||
    location.pathname === "/thatsall" ||
    /\/join\/[a-zA-Z0-9_]+/.test(location.pathname) ||
    location.pathname === "/getfunded"
  

    ;
  //console.log("is new match" + JSON.stringify(isNewMatch))
  const { currentUser } = useContext(authContext);
  const [userData, setUserData] = useState("");
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null)
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [navBarRef, { height }] = useMeasure();
  const [selectedMenuItem, setSelectedMenuItem] = useState("Home");
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  let navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setLogoutPopup(false);
      navigate("/");
      setSelectedMenuItem("home");
    } catch (error) {
      // console.log(error);
    }
  };

  const toggleLoginButton = () => {
    setShowLoginButton(!showLoginButton);
  };
  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      if (userId === "gr21pGWNxWYptQIpEJmE"){
        setIsAdmin(true)
      }
      return { userData, userId };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

  useEffect(() => {
    if (isDashboard) {
      setSelectedMenuItem("My Dashboard");
    } else if (isLandingPage) {
      setSelectedMenuItem("Home");
    } else if (isSettings) {
      setSelectedMenuItem("Settings");
    }
  }, [isDashboard, isLandingPage, isSettings]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { userData } = await getDataFromDb();
        setUserData(userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    // Hide the options when the page changes
    setShowLoginButton(false);
  }, [location]);

  useEffect(() => {
    const updateNavHeight = () => {
      props.updatedNavBarHeight(height);
    };
    updateNavHeight();
  }, [height, props]);

  return (
    <>
      {noNav && null}
      {isLandingPage && (
        <div
          style={{
            position: "fixed",
            top: "10",
            left: "0",
            right: "0",
            width: "100%",
            zIndex: "500",
            // backdropFilter: "blur(10px)",
            // other styles
          }}
          ref={mergeRefs([ref, navBarRef])}
          className="fixed top-0 left-0 right-0 w-full z-500  from-navStart to-navEnd shadow-lg text-[#f2f2f2] shadow-black/60 py-2 px-4 max-w-screen max-w-[100vw]"
        >
          {
            <div className="">
              <nav className=" w-full flex justify-between items-center lg:px-20 ">
                {!currentUser && <Link className="flex lg:justify-center items-center" to="/">
                  <img
                    className="h-8 md:h-14  mr-3 transition-all duration-500 ease-in-out delay-75"
                    src={Logo}
                    alt="Prairi"
                  />
                  <div className="w-20 lg:w-32">
                    <img
                      className="w-full"
                      src={PrairiTextLogo}
                      alt="Prairi Text"
                    />
                  </div>
                </Link>}
                {currentUser && <Link className="flex lg:justify-center items-center" to="/landing">
                  <img
                    className="h-8 md:h-14  mr-3 transition-all duration-500 ease-in-out delay-75"
                    src={Logo}
                    alt="Prairi"
                  />
                  <div className="w-20 lg:w-32">
                    <img
                      className="w-full"
                      src={PrairiTextLogo}
                      alt="Prairi Text"
                    />
                  </div>
                </Link>}
                <div className="text-[#c8c8c8]">
                  {showLoginButton ? (
                    <div className="flex">
                      {isMobile && currentUser && userData && (
                        <div
                          className="mr-2 text-[#f2f2f2] font-light flex justify-end items-end  "
                          style={{
                            maxWidth: "200px", // Set your desired maximum width
                            //overflow: "hidden",
                            whiteSpace: "nowrap",
                            //textOverflow: 'ellipsis'
                          }}
                        >
                          Welcome, {userData.contactName.split(" ")[0]}
                        </div>
                      )}
                      <button
                        onClick={toggleLoginButton}
                        className="lg:hidden mx-4 "
                      >
                        <CgClose className="text-2xl" />
                      </button>
                    </div>
                  ) : (
                    <div className="flex justify-end font-semibold h-full">
                      {isMobile && selectedMenuItem === "My Dashboard" && (
                        <Link
                          className="w-auto mr-8 font-semibold "
                          to={`/dashboard`}
                        >
                          {selectedMenuItem}
                        </Link>
                      )}
                      
                      {isMobile && selectedMenuItem === "Settings" && (
                        <Link
                          className="w-auto mr-8"
                          to={`/${selectedMenuItem}`}
                        >
                          {selectedMenuItem}
                        </Link>
                      )}

                      <div className="flex">
                        {isMobile && currentUser && userData && (
                          <div
                            className="mr-2 text-[#f2f2f2] font-light flex justify-end items-end  "
                            style={{
                              maxWidth: "200px", // Set your desired maximum width
                              //overflow: "hidden",
                              whiteSpace: "nowrap",
                              //textOverflow: 'ellipsis'
                            }}
                          >
                            Welcome, {userData.contactName.split(" ")[0]}
                          </div>
                        )}
                        {currentUser && (
                          <button
                            onClick={toggleLoginButton}
                            className="lg:hidden"
                          >
                            <VscMenu className="text-2xl ml-4 mr-4" />
                          </button>
                        )}
                      </div>
                      {!currentUser && isMobile && (
                        <Link
                          to="/login"
                          className=" font-bold text-sm md:text-base flex justify-end mr-2 md:mr-4 text-white"
                        >
                          Log in
                        </Link>
                      )}
                    </div>
                  )}
                </div>
                <div className="hidden lg:flex  md:flex-row items-center">
                  {currentUser && (
                    <>
                    {isAdmin && <Link
                        to="/admindashboard"
                        className={`font-bold text-sm md:text-base  md:mr-4 ${
                          selectedMenuItem === "Admin"
                            ? "text-white"
                            : "text-white"
                        }`}
                      >
                        Admin
                      </Link>}
                      <Link
                        to="/dashboard"
                        className={`font-bold text-sm md:text-base  md:mr-4 ${
                          selectedMenuItem === "My Dashboard"
                            ? "text-white"
                            : "text-white"
                        }`}
                      >
                        My Dashboard
                      </Link>
                      <Link
                        to="/settings"
                        className={`font-bold text-sm md:text-base mr-2 md:mr-4 ${
                          selectedMenuItem === "Settings"
                            ? "text-white"
                            : "text-white"
                        }`}
                        //onClick={() => setSelectedMenuItem("Settings")}
                      >
                        Settings
                      </Link>

                      <button
                        onClick={() => setLogoutPopup(true)}
                        to="/logout"
                        className=" font-bold text-sm md:text-base mr-2 md:mr-4"
                      >
                        Log out
                      </button>
                    </>
                  )}
                  {!currentUser && (
                    <Link
                      to="/login"
                      className=" font-bold text-sm md:text-base mr-2 md:mr-4 text-white"
                    >
                      Log in
                    </Link>
                  )}
                </div>
              </nav>
              {showLoginButton && (
                <div className="w-full mx-auto my-4 text-center text-sm md:text-base lg:hidden">
                  {currentUser && (
                    <div>
                                          {isAdmin && <Link
                        to="/admindashboard"
                        className={`font-bold text-sm md:text-base  md:mr-4 ${
                          selectedMenuItem === "Admin"
                            ? "text-white"
                            : "text-white"
                        }`}
                      >
                        Admin
                      </Link>}
                      <Link
                        to="/dashboard"
                        className={`font-bold text-sm md:text-base mr-8 md:mr-8  ${
                          selectedMenuItem === "My Dashboard"
                            ? "text-white"
                            : "text-silverPrairi"
                        }`}
                      >
                        My Dashboard
                      </Link>
                      <Link
                        to="/settings"
                        className={`font-bold text-sm md:text-base mr-8 md:mr-8 ${
                          selectedMenuItem === "Settings"
                            ? "text-white"
                            : "text-silverPrairi"
                        }`}
                        // onClick={() => setSelectedMenuItem("Settings")}
                      >
                        Settings
                      </Link>

                      <button
                        onClick={() => setLogoutPopup(true)}
                        to="/logout"
                        className="text-silverPrairi font-bold text-sm md:text-base mr-2 md:mr-4"
                      >
                        Log out
                      </button>
                    </div>
                  )}
                  {!currentUser && (
                    <>
                      <p className=" italic my-2">Already Registered?</p>
                      <Link
                        to="login"
                        reloadDocument
                        className="flex justify-center items-center"
                      >
                        <span>
                          <BiLogIn className="text-4xl md:text-5xl mr-2" />
                        </span>
                        <span className=" font-bold "> Log In</span>
                      </Link>
                    </>
                  )}
                </div>
              )}
            </div>
          }
        </div>
      )}

      {!isLandingPage && !noNav && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            width: "100%",
            zIndex: "500",
            // backdropFilter: "blur(10px)",
            // other styles
          }}
          ref={mergeRefs([ref, navBarRef])}
          className="bg-black shadow-lg text-[#f2f2f2] shadow-black/60 py-2 px-4"
        >
          
            <div className="bg-black">
              <nav className=" w-full flex justify-between items-center lg:px-20 ">
              {!currentUser && <Link className="flex lg:justify-center items-center" to="/">
                  <img
                    className="h-8 md:h-14  mr-3 transition-all duration-500 ease-in-out delay-75"
                    src={Logo}
                    alt="Prairi"
                  />
                  <div className="w-20 lg:w-32">
                    <img
                      className="w-full"
                      src={PrairiTextLogo}
                      alt="Prairi Text"
                    />
                  </div>
                </Link>}
                {currentUser && <Link className="flex lg:justify-center items-center" to="/landing">
                  <img
                    className="h-8 md:h-14  mr-3 transition-all duration-500 ease-in-out delay-75"
                    src={Logo}
                    alt="Prairi"
                  />
                  <div className="w-20 lg:w-32">
                    <img
                      className="w-full"
                      src={PrairiTextLogo}
                      alt="Prairi Text"
                    />
                  </div>
                </Link>}
                <div className="text-white flex justify-end h-full">
                  {showLoginButton ? (
                    <button onClick={toggleLoginButton} className="lg:hidden">
                      <CgClose className="text-3xl" />
                    </button>
                  ) : (
                    <div className="flex items-center w-auto h-full">
                      {isMobile && selectedMenuItem === "My Dashboard" && (
                        <Link
                          className="w-auto mr-8 font-semibold"
                          to={`/dashboard`}
                        >
                          {selectedMenuItem}
                        </Link>
                      )}
                      {isMobile && selectedMenuItem === "Settings" && (
                        <Link
                          className="w-auto mr-8 font-semibold"
                          to={`/${selectedMenuItem}`}
                        >
                          {selectedMenuItem}
                        </Link>
                      )}

                      <button onClick={toggleLoginButton} className="lg:hidden">
                        <VscMenu className="text-xl mr-6" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="hidden lg:flex  md:flex-row items-center">
                  {currentUser && (
                    <>
                                        {isAdmin && <Link
                        to="/admindashboard"
                        className={`font-bold text-sm md:text-base  md:mr-4 ${
                          selectedMenuItem === "Admin"
                            ? "text-white"
                            : "text-white"
                        }`}
                      >
                        Admin
                      </Link>}
                      <Link
                        to="/dashboard"
                        className={`font-bold text-sm md:text-base mr-2 md:mr-4  ${
                          selectedMenuItem === "My Dashboard"
                            ? "text-white"
                            : "text-[#c8c8c8]"
                        }`}
                      >
                        My Dashboard
                      </Link>
                      <Link
                        to="/settings"
                        className={`font-bold text-sm md:text-base mr-2 md:mr-4 ${
                          selectedMenuItem === "Settings"
                            ? "text-white"
                            : "text-[#c8c8c8]"
                        }`}
                        //onClick={() => setSelectedMenuItem("Settings")}
                      >
                        Settings
                      </Link>

                      <button
                        onClick={() => setLogoutPopup(true)}
                        to="/logout"
                        className=" font-bold text-[#c8c8c8] text-sm md:text-base mr-2 md:mr-4 text-[#c8c8c8]"
                      >
                        Log out
                      </button>
                    </>
                  )}
                  {!currentUser && (
                    <Link
                      to="/login"
                      className=" font-bold text-sm md:text-base mr-2 md:mr-4 text-customWhite"
                    >
                      Log in
                    </Link>
                  )}
                </div>
              </nav>
              {showLoginButton && (
                <div className="mx-auto my-4 text-center text-sm md:text-base lg:hidden">
                  {currentUser && (
                    <div className="w-full">
                      <Link
                        to="/dashboard"
                        className={`font-bold text-sm md:text-base  ${
                          selectedMenuItem === "My Dashboard"
                            ? "text-white"
                            : "text-[#c8c8c8]"
                        }`}
                      >
                        My Dashboard
                      </Link>
                      <Link
                        to="/settings"
                        className={`font-bold text-sm md:text-base mx-8 ${
                          selectedMenuItem === "Settings"
                            ? "text-white"
                            : "text-[#c8c8c8]"
                        }`}
                        //onClick={() => setSelectedMenuItem("Settings")}
                      >
                        Settings
                      </Link>

                      <button
                        onClick={() => setLogoutPopup(true)}
                        to="/logout"
                        className=" font-bold text-[#c8c8c8] text-sm md:text-base ml-5 md:mr-4"
                      >
                        Log out
                      </button>
                    </div>
                  )}
                  {!currentUser && (
                    <>
                      <p className=" italic my-2">Already Registered?</p>
                      <Link
                        to="login"
                        reloadDocument
                        className="flex justify-center items-center"
                      >
                        <span>
                          <BiLogIn className="text-4xl md:text-5xl mr-2" />
                        </span>
                        <span className=" font-bold"> Log In</span>
                      </Link>
                    </>
                  )}
                </div>
              )}
            </div>
          
        </div>
      )}
      <Modal open={logoutPopup} size="md">
        <LogoutConfirmation
          stay={() => setLogoutPopup(false)}
          logout={handleLogout}
        />
      </Modal>
    </>
  );
});

export default NavBar;
